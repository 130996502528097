<template>
  <div class="pa-4">

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">
          <h2 class="text-uppercase">PIECES JOINTES <span v-if="type === 'dossier'">DU DOSSIER</span><span
              v-if="type === 'bien_immobilier'">DU BIEN IMMOBILIER</span></h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="type === 'bien_immobilier'" no-gutters justify="space-between" align="center">

            <v-btn @click="downloadPhotos" color="primary">
              <v-icon>mdi-camera</v-icon>
              Télécharger les photos
            </v-btn>
          </v-row>
          <v-row v-if="type === 'dossier'" no-gutters justify="space-between" align="center">
            <v-dialog width="800" v-model="exportDialog">
              <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" color="primary">
                  <v-icon>mdi-file-document</v-icon>
                  Créer un export
                </v-btn>
              </template>
              <v-card>
                <v-toolbar color="primary">
                  <v-toolbar-title class="white--text">Créer un export</v-toolbar-title>
                </v-toolbar>
                <div class="pa-4">
                  <v-row>
                    <v-col v-for="attachment of allAttachments" :key="attachment.id" cols="4">
                      <v-row>
                        <v-col cols="1">
                          <v-checkbox multiple :value="attachment.id" v-model="exportAttachments"></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>AA{{ attachment.label }}</v-list-item-title>
                              <v-list-item-subtitle>{{ attachment.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <h4>Documents générés</h4>
                  <v-row>
                    <v-col v-for="document of allDocuments" :key="document.id" cols="4">
                      <v-row>
                        <v-col cols="1">
                          <v-checkbox multiple :value="document.id" v-model="exportDocuments"></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title v-if="document.model_doc !== null">{{ document.model_doc.name }}</v-list-item-title>
                              <v-list-item-title v-else class="font-italic">Document supprimé</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <h4>Factures</h4>
                  <v-row>
                    <v-col v-for="reglement of allFactures" :key="reglement.id" cols="6">
                      <v-row>
                        <v-col cols="1">
                          <v-checkbox multiple :value="reglement.id" v-model="exportFactures"></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-list-item-title>{{ reglement.saisie_source }}</v-list-item-title>
                              <v-list-item-subtitle>{{ reglement.montant + " €" }} - {{reglement.entreprise_name }} -
                                {{ getFormattedDate(reglement.date_reglement) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <h4>Export DEAL</h4>
                  <p v-if="missingForExportDEAL.length > 0">
                    Les documents suivants sont nécessaires pour l'export DEAL et sont actuellement manquants dans le
                    document :
                  </p>
                  <p v-else>
                    Les pièces sont prêtes pour ce dossier.
                  </p>
                  <ul>
                    <li v-for="(documentName, index) in missingForExportDEAL" :key="index">{{ documentName }}</li>
                  </ul>
                </div>
                <v-card-actions>
                  <v-btn color="primary" class="white--text" @click="createExport">Exporter</v-btn>
                  <v-btn color="primary" class="white--text" @click="createExportAsPdf">Exporter en PDF</v-btn>
                  <v-btn color="primary" class="white--text" v-if="missingForExportDEAL.length === 0"
                         @click="createDEALExport">Créer l'export DEAL
                  </v-btn>

                </v-card-actions>
              </v-card>
            </v-dialog>
            <SplitDocumentDialog :dossier="dossier"/>
            <v-btn icon @click="newDisplayStyle = !newDisplayStyle">
              <v-icon v-if="newDisplayStyle">mdi-view-dashboard</v-icon>
              <v-icon v-else>mdi-table</v-icon>
            </v-btn>
          </v-row>
          <v-treeview v-if="type === 'dossier' && newDisplayStyle"
                      class="attachment-treeview align-content-stretch align-stretch" :items="fileStructure">
            <template v-slot:append="{item}">
              <v-row class="light-border" no-gutters v-if="item.type !== 'dir'" justify="space-between" align="end">
                <v-spacer></v-spacer>
                <div>
                  <a v-if="item.file" target="_blank" :href="item.file">Voir le fichier</a>
                </div>
                <v-spacer></v-spacer>
                <div v-if="item.file && item.id && ['signed_doc', 'unsigned_doc', 'devis'].indexOf(item.type) === -1">
                  <v-tooltip v-if="!item.draft" bottom>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon @click="updateDraftStatus(item)" v-on="on" v-bind="attrs" color="orange">mdi-file-clock
                      </v-icon>
                    </template>
                    <span>Passer en doc de travail</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template @click="updateDraftStatus(item)" v-slot:activator="{on, attrs}">
                      <v-icon v-on="on" v-bind="attrs" color="primary">mdi-file-check</v-icon>
                    </template>
                    <span>Valider le document</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon v-on="on" v-bind="attrs" color="red">mdi-trash-can</v-icon>
                    </template>
                    <span>Supprimer le document</span>
                  </v-tooltip>
                  <v-progress-circular color="primary" indeterminate v-if="updating && updating_id === item.id"/>
                  <v-menu>
                    <v-list>
                      <v-list-item @click="updateAttachment(item, type)"
                                   v-for="(type, index) in attachments_types" :key="index">
                        <v-list-item-title>{{ type.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </v-row>

            </template>
          </v-treeview>
          <v-row style="max-height: 600px; overflow-y: auto" v-if="type === 'dossier' && !newDisplayStyle">
                    <v-col cols="12">
                      <v-text-field v-model="searchFile" label="Chercher une pièce" append-icon="mdi-search"></v-text-field>
                    </v-col>

            <v-col cols="6" v-for="attachment in documents" :key="attachment.id">
              <v-row>
                <v-col cols="10">
                  <v-list-item target="_blank" :href="attachment.file">
                    <v-list-item-icon v-if="hasThumbnail(attachment)">
                      <v-img max-width="50" :src="attachment.thumbnail"></v-img>
                    </v-list-item-icon>
                    <v-list-item-icon v-else>
                      <v-icon>mdi-file-document</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ attachment.label }}</v-list-item-title>
                      <v-list-item-subtitle>{{ getFilename(attachment.file) }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="attachment.date_expiration != null" class="orange--text font-weight-bold">
                        Expire le : {{ getFormattedDate(attachment.date_expiration) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="2">
                  <v-dialog max-width="600">
                    <v-card>
                      <v-toolbar color="red">
                        <v-toolbar-title class="white--text">Supprimer la pièce jointe</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pt-4">
                        <p>Confirmer la suppression ?</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="red" class="white--text" @click="deleteAttachment(attachment)">Supprimer</v-btn>
                      </v-card-actions>
                    </v-card>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                  </v-dialog>
                  <v-menu max-height="50vh">
                    <v-list>
                      <v-list-item @click="updateAttachment(attachment, type)"
                                   v-for="(type, index) in attachments_types" :key="index">
                        <v-list-item-title>{{ type.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                  <EditAttachmentDialog @reload="$emit('reload')" :attachment="attachment"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="type === 'bien_immobilier'">

            <v-col cols="12">
              <h2 class="primary--text">PHOTOS DU BIEN IMMOBILIER</h2>
            </v-col>
            <v-col md="4" cols="12" v-for="attachment in simplePhotos" :key="attachment.id">
              <v-card target="_blank" class="pa-2">
                <v-dialog max-width="800" v-if="hasThumbnail(attachment)">
                  <template v-slot:activator="{on, attrs}">
                    <v-img max-height="150" v-on="on" v-bind="attrs" v-if="attachment.thumbnail !== null"
                           :src="attachment.thumbnail" contain/>
                  </template>
                  <v-card max-width="800">
                    <v-toolbar color="primary">
                      <v-toolbar-title class="white--text">Prévisualisation</v-toolbar-title>
                    </v-toolbar>
                    <v-img contain :src="attachment.file"></v-img>
                  </v-card>
                </v-dialog>
                <v-sheet v-else class="white--text" height="150">
                  <v-row no-gutters align="center" justify="center">
                    <div class="mt-6">
                      <v-btn text icon target="_blank" :href="attachment.file">
                        <v-icon size="150">mdi-file-document</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-sheet>
                <v-card-title>{{ attachment.label }}</v-card-title>
                <v-card-subtitle>
                  {{
                    attachment.description !== "" && attachment.description !== null ? attachment.description : getFilename(attachment.file)
                  }}
                  <br> {{ attachment.file_size }} - Ajouté le {{ getFormattedDate(attachment.date_creation) }}
                </v-card-subtitle>
              </v-card>
              <v-btn class="mt-1" @click="deleteAttachment(attachment)" color="red" text>
                <v-icon color="red">mdi-trash-can</v-icon>
                Supprimer
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h2 class="primary--text text-uppercase">Photos avant chantier</h2>
            </v-col>
            <v-col md="4" cols="12" v-for="attachment in avantPhotos" :key="attachment.id">
              <v-card target="_blank" class="pa-2">
                <v-dialog max-width="800" v-if="hasThumbnail(attachment)">
                  <template v-slot:activator="{on, attrs}">
                    <v-img max-height="150" v-on="on" v-bind="attrs" v-if="attachment.thumbnail !== null"
                           :src="attachment.thumbnail" contain/>
                  </template>
                  <v-card max-width="800">
                    <v-toolbar color="primary">
                      <v-toolbar-title class="white--text">Prévisualisation</v-toolbar-title>
                    </v-toolbar>
                    <v-img contain :src="attachment.file"></v-img>
                  </v-card>
                </v-dialog>
                <v-sheet v-else class="white--text" height="150">
                  <v-row no-gutters align="center" justify="center">
                    <div class="mt-6">
                      <v-btn text icon target="_blank" :href="attachment.file">
                        <v-icon size="150">mdi-file-document</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-sheet>
                <v-card-title>{{ attachment.label }}</v-card-title>
                <v-card-subtitle>
                  {{
                    attachment.description !== "" && attachment.description !== null ? attachment.description : getFilename(attachment.file)
                  }}
                  <br> {{ attachment.file_size }} - Ajouté le {{ getFormattedDate(attachment.date_creation) }}
                </v-card-subtitle>
              </v-card>
              <v-btn class="mt-1" @click="deleteAttachment(attachment)" color="red" text>
                <v-icon color="red">mdi-trash-can</v-icon>
                Supprimer
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h2 class="primary--text text-uppercase">Photos après chantier</h2>
            </v-col>
            <v-col md="4" cols="12" v-for="attachment in apresPhotos" :key="attachment.id">
              <v-card target="_blank" class="pa-2">
                <v-dialog max-width="800" v-if="hasThumbnail(attachment)">
                  <template v-slot:activator="{on, attrs}">
                    <v-img max-height="150" v-on="on" v-bind="attrs" v-if="attachment.thumbnail !== null"
                           :src="attachment.thumbnail" contain/>
                  </template>
                  <v-card max-width="800">
                    <v-toolbar color="primary">
                      <v-toolbar-title class="white--text">Prévisualisation</v-toolbar-title>
                    </v-toolbar>
                    <v-img contain :src="attachment.file"></v-img>
                  </v-card>
                </v-dialog>
                <v-sheet v-else class="white--text" height="150">
                  <v-row no-gutters align="center" justify="center">
                    <div class="mt-6">
                      <v-btn text icon target="_blank" :href="attachment.file">
                        <v-icon size="150">mdi-file-document</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-sheet>
                <v-card-title>{{ attachment.label }}</v-card-title>
                <v-card-subtitle>
                  {{
                    attachment.description !== "" && attachment.description !== null ? attachment.description : getFilename(attachment.file)
                  }}
                  <br> {{ attachment.file_size }} - Ajouté le {{ getFormattedDate(attachment.date_creation) }}
                </v-card-subtitle>
              </v-card>
              <v-btn class="mt-1" @click="deleteAttachment(attachment)" color="red" text>
                <v-icon color="red">mdi-trash-can</v-icon>
                Supprimer
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-2" justify="space-around">
            <v-col lg="5" md="6" cols="12" v-if="type === 'dossier'">
              <v-card class="pa-4">
                <v-card-title class="primary--text text-center d-block">Ajout de pièce jointe</v-card-title>
                <v-form ref="attachmentForm">
                  <v-checkbox v-model="setCustomLabel" label="Label de document personnalisé"></v-checkbox>
                  <v-text-field v-if="setCustomLabel"
                                :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide']"
                                label="Label"
                                v-model="label"></v-text-field>
                  <v-select :rules="[value => value !== null || 'Ne doit pas être vide']" v-else v-model="label"
                            :items="file_types" item-value="value" item-text="name"></v-select>
                  <v-file-input :rules="[value => value !== null || 'Ne doit pas être vide']" label="Fichier"
                                v-model="file"></v-file-input>
                  <v-textarea label="Description" v-model="description"></v-textarea>
                  <div class="d-flex align-center justify-center">
                    <v-btn @click="uploadAttachment" color="primary">
                      <v-icon>mdi-plus</v-icon>
                      Ajouter la pièce jointe
                    </v-btn>
                  </div>
                </v-form>
              </v-card>
            </v-col>
            <v-col lg="5" md="6" cols="12">
              <v-card class="pa-4">
                <v-card-title class="primary--text text-center d-block">Ajout en masse</v-card-title>
                <v-form ref="attachmentForm">
                  <v-file-input multiple chips :rules="[value => value !== null || 'Ne doit pas être vide']"
                                label="Fichiers"
                                v-model="files"></v-file-input>
                  <v-checkbox v-model="isPhotoImport" label="Import de photos uniquement"></v-checkbox>
                  <div class="d-flex align-center justify-center">
                    <v-btn @click="uploadMassAttachments" color="primary">
                      <v-icon>mdi-plus</v-icon>
                      Ajouter les pièces jointes
                    </v-btn>
                  </div>
                </v-form>
                <v-progress-linear class="mt-6" v-if="uploadProgressMulti !== 0" :value="uploadProgressMulti"></v-progress-linear>
              </v-card>
            </v-col>
            <v-col lg="4" md="6" cols="12" v-if="type === 'bien_immobilier'">
              <v-card class="pa-4">
                <v-card-title class="primary--text text-center d-block">Ajout de photo</v-card-title>
                <v-form ref="photoForm">

                  <v-file-input :rules="[value => value !== null || 'Ne doit pas être vide', isFileImage]"
                                label="Fichier"
                                v-model="filePhoto"></v-file-input>
                  <v-textarea label="Description" v-model="descriptionPhoto"></v-textarea>
                  <v-radio-group v-model="typePhoto">
                    <v-radio value="normal" label="Photo générique"></v-radio>
                    <v-radio value="avant" label="Photo avant chantier"></v-radio>
                    <v-radio value="apres" label="Photo après chantier"></v-radio>
                  </v-radio-group>
                  <div class="d-flex align-center justify-center">
                    <v-btn @click="uploadPhoto" color="primary">
                      <v-icon>mdi-camera</v-icon>
                      Ajouter une photo
                    </v-btn>
                  </div>
                </v-form>
              </v-card>
            </v-col>

          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import {convertStringListToSelect, getFormattedDate} from "@/util";
import dayjs from "dayjs";
import SplitDocumentDialog from "@/views/SplitDocumentDialog.vue";
import {AuthMixin} from "@/mixins/AuthMixin";
import EditAttachmentDialog from "@/views/EditAttachmentDialog.vue";
// eslint-disable-next-line no-unused-vars
const NON_MANDATORY_DOCUMENTS = [
  "Mandat attributaire",
  "Convention relative au versement de l'apport personnel",
  "Attestation de non-DP",
  "Attestation de DP",
  "Photos",
  "Photo avant chantier",
  "Photo après chantier",
  "Plans"
];
const PHOTO_TYPES = {
  "normal": "Photo",
  "avant": "Photo avant chantier",
  "apres": "Photo après chantier",
}
const STRUCTURE = [
  {
    name: "1 - DOSSIER DEAL",
    type: "dir",
    children: [
      {
        name: "1 - DA - DOSSIER ADMINISTRATIF",
        type: "dir",
        children: [
          {
            name: "Pièces attributaires",
            type: "file",
          },

          {
            name: "Mandat attributaire",
            type: "file",
          },
          {
            name: "Formulaire DEAL",
            type: "file",
          }
        ]
      },
      {
        name: "2 - DOSSIER TECHNIQUE",
        type: "dir",
        children: [
          {
            name: "1 - PLANS",
            type: "dir",
            children: [
              {
                name: "Plans",
                type: "files",
              },
              {
                name: "Plan projet",
                type: "files",
              },
              {
                name: "Plan existant",
                type: "files",
              }
            ],
          },
          {
            name: "2 - DOSSIER PHOTOS",
            type: "dir",
            children: [
              {
                name: "Photo",
                type: "files",
              },
              {
                name: "Photo avant chantier",
                type: "files",
              },
              {
                name: "Photo après chantier",
                type: "files",
              },

            ],
          },
          {
            name: "Rapport d'analyse",
            type: "file",
          },
          {
            name: "Notice descriptive",
            type: "file",
          },
          {
            name: "Grille de dégradation",
            type: "file",
          },
          {
            name: "Devis",
            type: "file"
          },
          {
            name: "Fiche à priori",
            type: "file"
          },
          {
            name: "Attestation de non-DP",
            type: "file"
          },
          {
            name: "Attestation de DP",
            type: "file"
          },
          {
            name: "Attestation de non-commencement des travaux",
            type: "file",
          },
          {
            name: "Diagnostic BET",
            type: "file",
          }
        ],
      },
      {
        name: "3 - DOSSIER FINANCIER",
        type: "dir",
        children: [
          {
            name: "Contrat AMO",
            type: "file"
          },
          {
            name: "Attestation apport personnel",
            type: "file"
          },
          {
            name: "Convention relative au versement de l'apport personnel",
            type: "file"
          },
          {
            name: "Plan de financement",
            type: "file"
          },
          {
            name: "Procuration perception de fonds",
            type: "file"
          },
          {
            name: "Délégation de paiement",
            type: "file"
          },
        ]
      }
    ],
  },
  {
    name: "2 - DOSSIER CTM",
    type: "dir",
    children: [
      {
        name: "Lettre CTM",
        type: "file"
      }
    ]
  },
  {
    name: "3 - DOCS DE TRAVAIL",
    type: "dir",
    children: [
      {
        name: "Set administratif",
        type: "file"
      },
      {
        name: "Plan de financement",
        type: "files",
      }
    ]
  },
  {
    name: "4 - TRAVAUX",
    type: "dir",
    children: [
      {
        name: "Fiche expertise",
        type: "file",
      },
      {
        name: "Photo pré-visite",
        type: "dir",
        children: [
          {
            name: "Photos avant chantier",
            type: "files"
          }
        ]
      }
    ]
  },
  {
    name: "5 - DOCS CONTRACTUELS",
    type: "dir",
    children: [
      {
        name: "Échéancier",
        type: "file"
      },
      {
        name: "Convention d'entrée en relation",
        type: "file"
      },

    ]
  },
]
const EXTERN_ALLOWED_FILES = [
  "Plans",
  "Plan projet",
  "Plan existant",
  "Photo",
  "Photo avant chantier",
  "Photo après chantier",
  "Rapport d'analyse",
  "Notice descriptive",
  "Grille de dégradation",
  "Devis",
  "Fiche à priori",
  "Attestation de non-DP",
  "Attestation de DP",
  "Attestation de non-commencement des travaux",
  "Diagnostic BET",
]
export default {
  name: 'Attachments',
  components: {EditAttachmentDialog, SplitDocumentDialog},
  mixins: [AuthMixin],
  props: {
    dossier: Object,
    type: {
      type: String,
      default: "dossier",
    },
  },
  data() {
    return {
      file: null,
      files: [],
      isPhotoImport: false,
      filePhoto: null,
      description: "",
      descriptionPhoto: "",
      typePhoto: 'normal',
      label: null,
      setCustomLabel: false,
      exportAttachments: [],
      exportDocuments: [],
      exportFactures: [],
      exportDialog: false,
      newDisplayStyle: false,
      updating: false,
      updating_id: null,
      searchFile: "",
    }
  },
  async mounted() {
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  computed: {
    file_types() {
      const FILE_TYPES = {
        "dossier": this.attachments_types.map((type) => type.value),
        "bien_immobilier": ["PPRN", "Titre de propriété", "Photo", "Cadastre", "Autres"],
      }
      return this.isIntern ? convertStringListToSelect(FILE_TYPES[this.type]) : convertStringListToSelect(EXTERN_ALLOWED_FILES);
    },
    structure() {
      return STRUCTURE;
    },
    usedFileTypes() {
      return this.getAllFilesTypes(STRUCTURE);
    },
    fileStructure() {
      /** @type {String[]} */
      let usedFileTypes = this.getAllFilesTypes(STRUCTURE);
      let structure = this.buildDisplayStyle(this.structure);
      structure.push({
        name: "Non-catégorisé",
        type: "dir",
        children: this.structureAttachments.filter(attachement => usedFileTypes.indexOf(attachement.label) === -1)
      })
      return structure;
    },
    attachments() {
      return this.dossier.attachments;
    },
    allAttachments() {
      return this.dossier.attachments
          .map((attachment) => ({...attachment, name: attachment.label}))
          .filter(atttachment => this.isIntern ? true : EXTERN_ALLOWED_FILES.includes(atttachment.label))
    },
    /**
     *
     */
    structureAttachments() {
      let otherAttachments = [];
      // Documents

      for (let document of this.dossier.documents) {
        otherAttachments.push(
            {
              "label": document.model_doc?.name || "Document inconnu",
              "name": document.model_doc?.name || "Document inconnu",
              "file": document.signed_file ? document.signed_file : document.unsigned_file,
              "type": document.signed_file ? "signed_doc" : "unsigned_doc",
              "thumbnail": null,
              "description": "",
              "dossier": this.dossier.id,
            }
        )
      }
      // Plan de financement
      if (this.dossier.plan_financement) {
        otherAttachments.push(
            {
              "label": "Plan de financement",
              "name": "Plan de financement",
              "file": this.dossier.plan_financement,
              "type": "plan_financement",
              "thumbnail": null,
              "description": "",
              "dossier": this.dossier.id,
            }
        )
      }
      // Devis
      let foundDevis = this.dossier.devis.reduce((a, b) => {
        return dayjs(a.date_creation).isAfter(dayjs(b.date_creation)) ? a : b;
      }, {date_creation: "1971-01-01"});
      if (dayjs(foundDevis.date_creation).year() !== 1971) {
        otherAttachments.push(
            {
              "label": "Devis",
              "name": "Devis",
              "file": foundDevis.proposal_file,
              "type": "devis",
              "thumbnail": null,
              "description": "",
              "dossier": this.dossier.id,
            }
        )
      }


      return [...this.allAttachments, ...otherAttachments];
    },
    allDocuments() {
      return this.dossier.documents.filter((document) => document.signed_file !== null);
    },
    allFactures() {
      let factures = [];
      let saisies = this.dossier.saisies.filter((saisie) => saisie.type === "Décaissement") ?? [];
      for (const saisie of saisies) {
        for (const reglement of saisie.reglements) {
          if (reglement.file !== null) {
            factures.push(reglement);
          }
        }
      }
      return factures;
    },
    attachments_types() {
      return this.$store.getters["select/attachments_types"];
    },
    photos() {
      return this.attachments.filter(attachment => attachment.label.includes('Photo'))
          .filter(atttachment => this.isIntern ? true : EXTERN_ALLOWED_FILES.includes(atttachment.label));
    },
    simplePhotos() {
      return this.photos.filter((photo) => photo.label === "Photo");
    },
    avantPhotos() {
      return this.photos.filter((photo) => photo.label === "Photo avant chantier");
    },
    apresPhotos() {
      return this.photos.filter((photo) => photo.label === "Photo après chantier");
    },
    documents() {
      return this.attachments
          .filter(atttachment => this.isIntern ? true : EXTERN_ALLOWED_FILES.includes(atttachment.label))
          .filter(attachment => this.searchFile !== "" ? attachment.label.toLowerCase().indexOf(this.searchFile.toLowerCase()) !== -1 : true);
    },
    missingForExportDEAL() {
      let dossierDEAL = this.fileStructure[0];
      return this.getMissingDocumentsForExportDEAL(dossierDEAL);
    },
    uploadProgressMulti() {
      return this.$store.getters["attachments/uploadMultiProgress"];
    }
  },
  methods: {
    getMissingDocumentsForExportDEAL(node) {
      let documents = []
      for (const element of node.children) {
        if (element.type === "file" && element.file === undefined && !NON_MANDATORY_DOCUMENTS.includes(element.name)) {
          documents.push(element.name);
        }
        if (element.type === "files" && element.file === undefined && !NON_MANDATORY_DOCUMENTS.includes(element.name)) {
          documents.push(element.name);
        }
        if (element.type === "dir") {
          let subDocuments = this.getMissingDocumentsForExportDEAL(element);
          documents = documents.concat(subDocuments);
        }
      }
      return documents;
    },
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    hasThumbnail(attachment) {
      return attachment.thumbnail !== null && attachment.thumbnail !== undefined;
    },
    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image' || "Le document n'est pas une photo";
    },
    buildDisplayStyle(structure) {
      let iteratedStructure = JSON.parse(JSON.stringify(structure));
      let newStructure = [];
      for (var item of iteratedStructure) {
        if (item.type === "dir") {
          let folder = {...item, children: this.buildDisplayStyle(item.children)}
          newStructure.push(folder);
        }
        if (item.type === "file") {
          let attachment = this.structureAttachments.find(attachment => attachment.label === item.name);
          if (attachment !== undefined) {
            newStructure.push({...item, ...attachment});
          } else {
            newStructure.push({...item});
          }
        }
        if (item.type === "files") {
          let attachments = this.structureAttachments.filter((attachment) => attachment.label === item.name);
          if (attachments.length === 0) {
            newStructure.push({...item});
          }
          for (let attachment of attachments) {
            newStructure.push({...item, ...attachment});
          }
        }
      }
      return newStructure;
    },
    async uploadAttachment() {
      if (this.$refs.attachmentForm.validate()) {
        let attachment = {
          file: this.file,
          label: this.label,
          type: this.type,
          description: this.description,
          dossier: this.dossier.id
        }
        let result = await this.$store.dispatch("attachments/postDossierAttachment", attachment);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe ajoutée.")
          this.$emit("reload");
        }
        this.file = null;
        this.label = null;
        this.description = "";
      }
    },
    async uploadMassAttachments() {
      let result = await this.$store.dispatch("attachments/postMultipleAttachments", {
        files: this.files,
        is_photo: this.isPhotoImport,
        dossier: this.dossier.id,
      });
      if (result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe ajoutée.")
        this.$emit("reload");
      }
      this.files = [];
      this.isPhotoImport = false;
    },
    async exportAttachment() {

    },
    async updateAttachment(attachment, type) {
      this.updating = true;
      this.updating_id = attachment.id;
      let newAttachment = {id: attachment.id, label: type.value};
      let result = await this.$store.dispatch("attachments/patchAttachment", newAttachment);
      if (result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe modifié.")
        this.$root.$emit("refresh");
      }
      this.updating_id = null;
      this.updating = false;
    },
    async updateDraftStatus(attachment) {
      this.updating = true;
      this.updating_id = attachment.id;
      let newAttachment = {id: attachment.id, draft: !attachment.draft};
      let result = await this.$store.dispatch("attachments/patchAttachment", newAttachment);
      if (result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe modifié.")
        this.$root.$emit("refresh");
      }
      this.updating_id = null;
      this.updating = false;
    },
    async uploadPhoto() {
      if (this.$refs.photoForm.validate()) {
        let attachment = {
          file: this.filePhoto,
          label: PHOTO_TYPES[this.typePhoto],
          type: "bien_immobilier",
          description: this.descriptionPhoto,
          dossier: this.dossier.id
        }
        let result = await this.$store.dispatch("attachments/postDossierAttachment", attachment);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe ajoutée.")
          this.$root.$emit("refresh");
        }
        this.filePhoto = null;
        this.descriptionPhoto = "";
      }
    },
    async downloadPhotos() {
      await this.$store.dispatch("dossier/getDossierPhotos", this.dossier);
    },
    async createExport() {
      await this.$store.dispatch("dossier/createExport", {
        id: this.dossier.id,
        files: this.exportAttachments,
        documents: this.exportDocuments,
        factures: this.exportFactures,
        type: "normal"
      });
      this.exportAttachments = [];
      this.exportDocuments = [];
      this.exportFactures = [];
    },
    async createDEALExport() {
      await this.$store.dispatch("dossier/createExport", {
        id: this.dossier.id,
        type: "deal"
      });
      this.exportAttachments = [];
      this.exportDocuments = [];
      this.exportFactures = [];
    },
    async createExportAsPdf() {
      await this.$store.dispatch("dossier/createExport", {
        id: this.dossier.id,
        files: this.exportAttachments,
        documents: this.exportDocuments,
        factures: this.exportFactures,
        type: "pdf",
      });
      this.exportAttachments = [];
      this.exportDocuments = [];
      this.exportFactures = [];
    },
    async deleteAttachment(attachment) {
      let result = await this.$store.dispatch("attachments/deleteDossierAttachment", attachment);
      if (result === "success") {
        await this.$store.dispatch("annonce/annonceSuccess", "Pièce jointe supprimée.")
        this.$root.$emit("refresh");
      }
    },
    getAllFilesTypes(structure) {
      let iteratedStructure = JSON.parse(JSON.stringify(structure));
      let usedNames = [];
      for (var item of iteratedStructure) {
        if (item.type === "dir") {
          let subUsedNames = this.getAllFilesTypes(item.children);
          usedNames = usedNames.concat(subUsedNames);
        } else {
          usedNames.push(item.name);
        }
      }
      return [...new Set(usedNames)];
    },
  },
}
</script>
<style>
.attachment-treeview > .v-treeview-node > div.v-treeview-node__children > .v-treeview-node > div.v-treeview-node__children > .v-treeview-node > .v-treeview-node__children > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__content > div.v-treeview-node__append {
  flex: 2 !important;
}

.attachment-treeview > .v-treeview-node > div.v-treeview-node__children > .v-treeview-node > .v-treeview-node__children > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__content > div.v-treeview-node__append {
  flex: 2 !important;
}

.attachment-treeview > .v-treeview-node > .v-treeview-node__children > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__content > div.v-treeview-node__append {
  flex: 2 !important;
}

.attachment-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__content > div.v-treeview-node__append {
  flex: 2 !important;
}
</style>